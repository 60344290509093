import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './about.module.scss';

import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'
import ServiceCard from '../../service_card/service_card.component';

class About extends React.Component {
    componentDidMount() {
        Events.scrollEvent.register('begin', function(to, element) {
            console.log('begin', arguments);
          });
      
        Events.scrollEvent.register('end', function(to, element) {
        console.log('end', arguments);
        });
    
        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    scrollToBottom = () => {
        scroll.scrollToBottom();
    }

    scrollTo = () => {
        scroll.scrollTo(100);
    }

    scrollMore = () => {
        scroll.scrollMore(500);
    }

    handleSetActive = (to) => {
        console.log(to);
    }

    render() {
        return (
            <div className={classes.about}>
                <Helmet>
                    <title>About us | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <ServiceCard 
                    title="About Us"
                    details="Welcome to Se7enCoders Inc. A Canada based IT company which is your number one source for all coding solution. We're dedicated to giving you the very best of work with a focus on- web development, software development, App development, UI/UX design and ML."
                    image="about.svg"
                    button="Read More"
                    rightImage
                    scr={this.scrollMore}
                />
                <ServiceCard 
                    title="Our History"
                    details="Founded in 2013 by Sam Ross and Mike Burgess, Se7enCoders Inc. has come a long way from its beginnings in Ontario. When they first started out, their passion for quality development drove them to do tons of research to establish an effective professional company so that Se7enCoders Inc. can offer you best development with the world's most advanced technology available. "
                    image="vision.svg"
                    
                />
                <ServiceCard 
                    title="Our Mission"
                    details="Our mission is to ensure effective communication, offering unparalleled quality work at transparent pricing. We hope you enjoy our works as much as we enjoy offering them to you. If you have any question, please don't hesitate to contact us."
                    image="mission.svg"
                    rightImage
                />
            </div>
        )
    }
}

export default About;