import React from 'react';
import { Link } from 'react-router-dom'

import classes from './button.module.scss';

class Button extends React.Component {
    render() {
        return (
            <Link to={this.props.link} className={classes.btn} style={{backgroundColor: `${this.props.color}`}}>{this.props.text}</Link>
        )
    }
}

export default Button;