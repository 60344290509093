import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './contact.module.scss';
import { ReactSVG } from 'react-svg';

import { MdLocationOn } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

class Contact extends React.Component {
    render() {
        return (
            <div className={classes.contact}>
                <Helmet>
                    <title>Contact us | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        <div className={classes.content}>
                            <h2 className={classes.title}>Questions?</h2>
                            <h3 className={classes.subtitle}>Contact us</h3>
                            <p className={classes.details}>Want to order something or have any question about our services? Please contact us anytime you want!</p>
                            <div className={classes.contact_details}>
                                <div className={classes.loc}>
                                    <MdLocationOn />
                                </div>
                                <p className={classes.address}>1695 Victoria Park Ave,<br/>Toronto, ON M2J 3T7</p>
                                <div className={classes.flex}>
                                    <div className={classes.icon}>
                                        <FaPhoneAlt />
                                    </div>
                                    <div className={classes.phone}>
                                    +1 289 207 6881
                                    </div>
                                </div>
                                <div className={classes.flex2}>
                                    <div className={classes.icon}>
                                        <MdEmail />
                                    </div>
                                    <div className={classes.email}>
                                        contact@se7encoders.com
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.img}>
                            <ReactSVG src="./contact.svg" alt="Contact us"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;