import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './error.module.scss';
import { ReactSVG } from 'react-svg';

import PageTitle from '../../page_title/page_title.component';
import Button from '../../button/button.component';

class Page404 extends React.Component {
    render() {
        return (
            <div className={classes.error}>
                <Helmet>
                    <title>Page Not Found | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <div className={classes.title}>
                    <PageTitle title='Error 404: Page Not Found!'/>
                </div>
                <div className={classes.container}>
                    <ReactSVG src="./Error404.svg" alt="Error 404: Page Not Found"/>
                    <div className={classes.btn}>
                        <Button text='Back to Home' link='/'/>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Page404;