import React from 'react';

import classes from './contact_info.module.scss';

import { MdLocationOn } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

class ContactInfo extends React.Component {
    render() {
        return (
            <div className={classes.contact}>
                <div className={classes.icon}>
                    <MdLocationOn />
                </div>
                <p className={classes.address}>1695 Victoria Park Ave,<br/>Toronto, ON M2J 3T7</p>
                <div className={classes.phone_wrapper}>
                    <p className={classes.phone}> <FaPhoneAlt /></p>
                    <p>+1 289 207 6881</p>
                </div>
                <div className={classes.mail_wrapper}>
                    <p className={classes.email}> <MdEmail/> </p>
                    <p className={classes.email_text}>contact@se7encoders.com</p>
                </div>
            </div>
        )
    }
}

export default ContactInfo