import React from 'react';

import classes from './service_card.module.scss';

import Features from '../features/features.component';
import Button from '../button/button.component';
import { ReactSVG } from 'react-svg';
class ServiceCard extends React.Component {
    render() {

        let classname;

        if(this.props.rightImage){
            classname= classes.flex_wrapper;
        } else {
            classname = classes.flex_wrapper2;
        }


        return (
            <div className={classes.service_card}>
                <div className={classes.container}>
                    <div className={classname}>
                        <div className={classes.content} style={{marginTop: `${this.props.margintop}`}}>
                            <h2 className={classes.title}>{this.props.title}</h2>
                            <p className={classes.details}>{this.props.details}</p>
                            {this.props.features && <Features features={this.props.features}/>}
                            {this.props.scr && <div onClick={this.props.scr}>
                                {this.props.button && <Button text={this.props.button} className={classes.btn}/>}
                            </div>}
                        </div>
                        <div className={classes.image}>
                            <ReactSVG src={this.props.image} alt={this.props.title}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceCard;