import React from 'react';

import classes from './backdrop.module.scss';

class Backdrop extends React.Component {
    render() {
        return (
            <div className={classes.backdrop} onClick={this.props.click}></div>
        )
    }
}

export default Backdrop;