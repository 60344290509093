import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './career_single.module.scss';

import Page404 from '../404/error.component';

class CareerSingle extends React.Component {

    state = {
        opens: [
            {
                id: '1',
                position: 'Junior React Developer',
                experience: '2 Years',
                salary: '$90K / Year',
                skills: 'React, Redux, JavaScript (ES6+), Component, Hooks',
                loc: 'Remote',
                desc: "Se7enCoders Inc. is an online service provider of various fields such as Web Development, App Development, Software Development, Data Analysis and Machine Learning. We, Se7enCoders are currently looking for a front-end developer (React) to join in our dynamic team. We always prefer self-taught programmer who can self-learn and expand their knowledge according to the needs. Workshops and seminars would be provided for further skill enhancement of the team members. We invite you to apply for this position if you’re confident enough and have the coding ability to stand out among others!",
                resp: ["Can work with a team efficiently", "Has time management skills", "Write efficient and clean code", "Develop front-end for our clients", "Apply the industry standards when coding", "Has Version Control ability (Preferably Git)", "Knowledge in Cloud Services is a plus"]
            },
            {
                id: '2',
                position: 'Node.js Developer',
                experience: '3 Years',
                salary: '$100K / Year',
                skills: 'REST API, GraphQL API, Express, JavaScript (ES6+)',
                loc: 'Toronto/Remote',
                desc: "Se7enCoders Inc. is an online service provider of various fields such as Web Development, App Development, Software Development, Data Analysis and Machine Learning. We, Se7enCoders are currently looking for a back-end developer (Node.js) to join in our dynamic team. We always prefer self-taught programmer who can self-learn and expand their knowledge according to the needs. Workshops and seminars would be provided for further skill enhancement of the team members. We invite you to apply for this position if you’re confident enough and have the coding ability to stand out among others!",
                resp: ["Can work with a team efficiently", "Has time management skills", "Write efficient and clean code", "Develop back-end with Node.js for our clients", "Apply the industry standards when coding", "Has Version Control ability (Preferably Git)", "Knowledge in Cloud Services is a plus"]
            }
        ]
    }



    render() {

        let response;

        if(this.state.opens[this.props.match.params.id - 1]){
            const { position, experience, salary, skills, loc, desc, resp} = this.state.opens[this.props.match.params.id - 1]

            const resplist = resp.map(res => <li key={res}>{res}</li>)

            response = (
                <div className={classes.career}>
                    <Helmet>
                        <title>{position} - Careers | Se7enCoders</title>
                        <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                    </Helmet>
                    <div className={classes.container}>
                        <h2 className={classes.position}>{position} | Se7enCoders Inc</h2>
                        <p className={classes.description}>{desc}</p>
                        <div className={classes.resp}>
                            <p>Responsibilities: </p>
                            <div className={classes.resp_list}>
                                {resplist}
                            </div>
                        </div>
                        <p className={classes.skills}>Required Skills: {skills}</p>
                        <p className={classes.experience}>Experience: {experience}</p>
                        <p className={classes.location}>Location: {loc}</p>
                        <p className={classes.salary}>Salary: {salary}</p>
                        <p className={classes.apply}>APPLY NOW: Please send us your application with CV in the <span className={classes.mail}>hr@se7encoders.com</span> address with subject “{position}”. Thank you!</p>
                    </div>
                </div>
            )
        } else {
            response = <Page404 />
        }
        

        return response;
    }
}

export default CareerSingle;