import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './header_primary.module.scss';
import { ReactSVG } from 'react-svg';

import Toggler from './toggler/toggler.component';
import SideDrawer from './side_drawer/side_drawer.component';
import Backdrop from './backdrop/backdrop.component';

class HeaderPrimary extends React.Component {

    state = {
        sideDrawerOpen: false
    };

    handleDrawerToggle = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        });
    };

    handleBackdropClick = () => {
        this.setState({sideDrawerOpen: false});
    }

    render() {
        let backDrop;

        if(this.state.sideDrawerOpen){
            backDrop = <Backdrop click={this.handleBackdropClick}/>
        }

        let classname;

        if(this.props.data){
            classname = classes.active;
        }

        return (
            <div className={classes.header}>
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        <div className={classes.logo}>
                            <NavLink to="/"><ReactSVG src="icon-dark.svg" style={{ width: '100px'}}/></NavLink>
                        </div>
                        <div className={classes.menu_wrapper}>
                            
                            <ul className={classes.menu} onClick={this.props.click}>
                                <li className={classes.menu_item}><NavLink to="/" exact activeClassName={classname}>Home</NavLink></li>
                                <li className={classes.menu_item}><NavLink to="/services" activeClassName={classname}>Services</NavLink></li>
                                <li className={classes.menu_item}><NavLink to="/peoples" activeClassName={classname}>Peoples</NavLink></li>
                                <li className={classes.menu_item}><NavLink to="/careers" activeClassName={classname}>Careers</NavLink></li>
                                <li className={classes.menu_item}><NavLink to="/login" activeClassName={classname}>Login Portal</NavLink></li>
                            </ul>
                            <Toggler toggleHandler={this.handleDrawerToggle}/>
                            <SideDrawer show={this.state.sideDrawerOpen}/>
                            {backDrop}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderPrimary;