import React from 'react';
import { Helmet } from 'react-helmet';

import PageTitle from '../../page_title/page_title.component';
import ServiceCard from '../../service_card/service_card.component';

class Services extends React.Component {
    render() {

        return (
            <div>
                <Helmet>
                    <title>Our Services | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <PageTitle title='Services'/>
                <ServiceCard 
                    title='Web Development'
                    details= 'Looking for a blazing fast, complete website with custom features? We’re here to help. We provide web development solutions that uses the latest technology of the industry and follows the best practices among the community. Get your website ready within a short period of time with custom features of your own!'
                    image = "./Web.svg"
                    features={['Responsive Web Design', 'HTML5, CSS3/SASS with React', 'PHP/Python/Node.js as Backend Solution', 'Error-free, Blazing Fast Web Solutions']}
                    rightImage
                />
                <ServiceCard 
                    title='App Development'
                    details= 'Our app development solutions offers both Native and Cross-Platform app development, and the choice is yours. Our solution includes corporate app, business app, gambling app and many other types. Get a stable and smooth-running app for your company, with a clean code base!'
                    image = "./app.svg"
                    features={['Native App Development (Android, iOS)', 'Cross-Platform App Development', 'Clean Codebase with Efficient Coding', 'We Follow the Industry Standards']}
                />
                <ServiceCard 
                    title='Software Development'
                    details= 'Software is one of the most important tools in today’s world, and we understand it. Our software solution provides various types of software, following the Industry Standard tools and frameworks. Whether you want to develop a custom software for your team or business, our software solutions will meet your requirement and expectation!'
                    image = "./soft.svg"
                    features={['Exceptional Software Solutions', 'C, C++, C#, Java/JavaScript (ES6+)/Python as Backend Solutions', 'Bug-free, Fast and Efficient Codebase', 'Custom Features According to Your Needs']}
                    rightImage
                />
                <ServiceCard 
                    title='UI/UX Design'
                    details= 'Whether you’re building a website, application or software, User Interface plays a vital role. Our UI/UX Design solutions offers simple yet attractive UI for your web, app or software. The pixel perfect design and perfect color-contrast combination makes our solution one of a kind among others!'
                    image = "./ui.svg"
                    features={['Pixel Perfect Design', 'Perfect Color-Contrast Combo', 'Clean and Intuitive UI for Better User Experience', 'Easy-to-use, User-friendly UI']}
                />
                <ServiceCard 
                    title='Data Analysis'
                    details= 'In Today’s world, DATA IS POWER! Yes, and we understand the power of data and extract meaningful information and decisions from it. Our Data analysis solution includes analyzing data for commercial, finance and business entities, and our goal is to data into meaningful insights and information!'
                    image = "./data.svg"
                    features={['Exploratory Data Analysis', 'Industry Standard tools including NumPy, Pandas, Matplotlib, Seaborn etc.', 'Machine Learning for Predicting Future Insights', 'Optimize Operations with Valuable Data Insights']}
                    rightImage
                />
                <ServiceCard 
                    title='Machine Learning'
                    details= 'Machine Learning, the buzz word of this century – expresses the value and importance of it on its own. Our machine learning solution provides various type of machine learning approaches including Classification, Regression, Unsupervised Learning, NLP and Deep Learning (with CNN and RNN)'
                    image = "./ml.svg"
                    features={['Predict the Future with the Help of ML', 'From Simple Model to Multi-Layer Neural Networks', 'Application of Industry Standard Tools', 'Modern Deep Learning (with CNN and RNN)']}
                />
            </div>
            
        )
    }
}

export default Services;