import React from 'react';

import classes from './toggler.module.scss';

import { FaBars } from "react-icons/fa";

class Toggler extends React.Component {
    render() {
        return (
            <a 
                href='/#' className={classes.bars}
            onClick={this.props.toggleHandler}
            >
                <FaBars />
            </a>
        )
    }
}

export default Toggler;