import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './careers.module.scss';

import CareersListSingle from '../../careers_list_single/careers_list_single.component';
import PageTitle from '../../page_title/page_title.component';

class Careers extends React.Component {

    state = {
        opens: [
            {
                id: '1',
                position: 'Junior React Developer',
                experience: '2 Years',
                salary: '$90K / Year',
                skills: 'React, Redux, JavaScript (ES6+), Component, Hooks',
                loc: 'Remote'
            },
            {
                id: '2',
                position: 'Node.js Developer',
                experience: '3 Years',
                salary: '$100K / Year',
                skills: 'REST API, GraphQL API, Express, JavaScript (ES6+)',
                loc: 'Toronto/Remote'
            }
        ]
    }


    render() {

        const renderedCareersList = this.state.opens.map(opening => <CareersListSingle opening={opening} key={opening.id} />)
        console.log(this.state.opens)


        return (
            <div className={classes.careers}>
                <Helmet>
                    <title>Careers | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <PageTitle title='Current Openings' />
                <div className={classes.container}>
                    {renderedCareersList}
                </div>
            </div>
        )
    }
}

export default Careers;