import React from 'react';
import { Link } from 'react-router-dom';

import classes from './footer.module.scss';

import logo from './logo-white.svg';

import ContactInfo from '../contact_info/contact_info.component';


class Footer extends React.Component {
    render(){
        return (
        <div>
            <div className={classes.footer}>
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        
                        <div className={classes.about}>
                            <img src={logo} alt="se7enCoders"/>
                            <p>Welcome to Se7enCoders Inc. A Canada based IT company which is your number one source for all coding solution. We're dedicated to giving you the very best of work with a focus on- web development, software development, App development, UI/UX design and ML.</p>
                        </div>
                        
                        <div className={classes.links}>
                            <h2 className={classes.title}>Useful Links</h2>
                            <ul className={classes.list}>
                                <li><Link to='/toc'>Terms & Conditions</Link></li>
                                <li><Link to='privacy_policy'>Privacy Policy</Link></li>
                                <li><Link to='about_us'>About Us</Link></li>
                                <li><Link to='contact_us'>Contact Us</Link></li>
                                <li><Link to='careers'>Careers</Link></li>
                            </ul>
                        </div>

                        <ContactInfo />

                    </div>
                    
                </div>
            </div>
            <div className={classes.copy}>
                <p>Copyright ©2013-2021 | <Link to="/">Se7enCoders Inc.</Link> | All Rights Reserved!</p>
            </div>
        </div>
        )
    }
}

export default Footer;