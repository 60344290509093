import React from 'react';
import { Link } from 'react-router-dom';

import classes from './card.module.scss';



class Card extends React.Component {
    render() {

        let card;

        if (!this.props.link){
            card = (
                <div className={classes.card}>
                    {this.props.icon && <div className={classes.icon}>{this.props.icon}</div>}            
                    {this.props.title && <h2 className={classes.title}>{this.props.title}</h2>}
                    {this.props.subtitle && <p className={classes.subtitle}>{this.props.subtitle}</p>}
                    
                    
                    {this.props.image && <div className={classes.image}><img src={this.props.image} alt=""/></div>}            
                    {this.props.name && <h2 className={classes.name}>{this.props.name}</h2>}
                    {this.props.designation && <h3 className={classes.designation}>{this.props.designation}</h3>}
                    <div className={classes.line}></div>
                </div>
            );
        } else {
            card = (
                <Link to={this.props.link} className={classes.link}>
                    <div className={classes.card}>
                        {this.props.icon && <div className={classes.icon}>{this.props.icon}</div>}            
                        {this.props.title && <h2 className={classes.title}>{this.props.title}</h2>}
                        {this.props.subtitle && <p className={classes.subtitle}>{this.props.subtitle}</p>}
                        
                        
                        {this.props.image && <div className={classes.image}><img src={this.props.image} alt=""/></div>}            
                        {this.props.name && <h2 className={classes.name}>{this.props.name}</h2>}
                        {this.props.designation && <h3 className={classes.designation}>{this.props.designation}</h3>}
                        <div className={classes.line}></div>
                    </div>
                </Link>
            );
        }

        return card;
    }
}

export default Card;