import React from 'react';


import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.module.scss';

import Scroll from "react-scroll-to-top";

import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import HomePage from './components/pages/homepage/homepage.component';
import Services from './components/pages/services/services.component';
import Peoples from './components/pages/peoples/peoples.component';
import Careers from './components/pages/careers/careers.component';
import CareerSingle from './components/pages/career_single/career_single.component';
import LoginPortal from './components/pages/login_portal/login_portal.component';

import PrivacyPolicy from './components/pages/privacy_policy/privacy_policy.component';
import TOC from './components/pages/toc/toc.component';
import About from './components/pages/about/about.component';
import Contact from './components/pages/contact/contact.component';

import Page404 from './components/pages/404/error.component';

import ScrollToTop from './components/scroll_to_top/scroll_to_top.component';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Scroll smooth color='orange'/>
      <Header />
      <Switch>
        
        <Route path='/' exact>
          <HomePage />
        </Route>
        <Route path='/services' exact>
          <Services />
        </Route>
        <Route path='/peoples' exact>
          <Peoples />
        </Route>
        <Route path='/careers' exact>
          <Careers />
        </Route>
        <Route path='/careers/:id' exact component={CareerSingle} />

        <Route path='/login' exact>
          <LoginPortal />
        </Route>

        
        
        <Route path='/privacy_policy' exact>
          <PrivacyPolicy />
        </Route>
        <Route path='/toc' exact>
          <TOC />
        </Route>
        <Route path='/about_us' exact>
          <About />
        </Route>
        <Route path='/contact_us' exact>
          <Contact />
        </Route>



        <Route path='/*'>
          <Page404 />
        </Route>


      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
