import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './side_drawer.module.scss';
import { ReactSVG } from 'react-svg';

class SideDrawer extends React.Component {
    render() {

        let drawerClasses = [classes.tray]
        if(this.props.show){
            drawerClasses = [classes.open]
        }

        return (
            <div className={drawerClasses}>
                <NavLink className={classes.tray_logo} to="/"><ReactSVG src="./icon-dark.svg" alt="Se7enCoders"/></NavLink>
                <ul className={classes.tray_list}>
                    <li className={classes.tray_item}><NavLink to="/" exact >Home</NavLink></li>
                    <li className={classes.tray_item}><NavLink to="/services" >Services</NavLink></li>
                    <li className={classes.tray_item}><NavLink to="/peoples" >Peoples</NavLink></li>
                    <li className={classes.tray_item}><NavLink to="/careers" >Careers</NavLink></li>
                    <li className={classes.tray_item}><NavLink to="/login" >Login Portal</NavLink></li>
                </ul>
            </div>
        )
    }
}

export default SideDrawer;