import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './header_secondary.module.scss';


import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";

class HeaderSecondary extends React.Component {
    render() {

        let classname;

        if(!this.props.data){
            classname = classes.active;
        }


        return (
            <div className={classes.header}>
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        <div className={classes.contact}>
                            <p className={classes.phone_icon}><FaPhoneAlt /></p>
                            <p className={classes.phone}> +1 289 207 6881</p>
                            <p className={classes.email_icon}><FaEnvelope /></p>
                            <p className={classes.email}> contact@se7encoders.com</p>
                        </div>
                        <ul className={classes.pages} onClick={this.props.click}>
                            <li className={classes.page_item}><NavLink to="/privacy_policy" activeClassName={classname}>Privacy Policy</NavLink></li>
                            <li className={classes.page_item}><NavLink to="/toc" activeClassName={classname}>Terms & Conditions</NavLink></li>
                            <li className={classes.page_item}><NavLink to="/about_us" activeClassName={classname}>About us</NavLink></li>
                            <li className={classes.page_item}><NavLink to="/contact_us" activeClassName={classname}>Contact us</NavLink></li>
                        </ul>
                        <div className={classes.social}>
                            <a href="/#" className={classes.social_item}><FaFacebookSquare /></a>
                            <a href="/#" className={classes.social_item}><FaTwitterSquare /></a>
                            <a href="/#" className={classes.social_item}><FaLinkedin /></a>
                            <a href="/#" className={classes.social_item}><FaYoutubeSquare /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderSecondary;