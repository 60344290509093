import React from 'react';

import classes from './page_title.module.scss';

class PageTitle extends React.Component {
    render() {
        return (
            <div className={classes.title}>
                <div className={classes.container}>
                    <h1>{this.props.title}</h1>
                </div>
            </div>
        )
    }
}

export default PageTitle;