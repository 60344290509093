import React from 'react';

import classes from './careers_list_single.module.scss';

import Button from '../button/button.component';

class CareersListSingle extends React.Component {
    render() {

        const { id, position, salary, experience, skills, loc } = this.props.opening;

        return (
            <div className={classes.career}>
                <div className={classes.flex_wrapper}>
                    <div className={classes.details}>
                        <p className={classes.position}><strong>Position: </strong>{position}</p>
                        <p className={classes.experience}><strong>Experience: </strong>{experience}</p>
                        <p className={classes.salary}><strong>Salary: </strong>{salary}</p>
                        <p className={classes.skills}><strong>Required Skills: </strong>{skills}</p>
                        <p className={classes.location}><strong>Location: </strong>{loc}</p>
                    </div>
                    <div className={classes.apply_btn}>
                        <Button text='apply now' link={`/careers/${id}`}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CareersListSingle;