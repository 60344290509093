import React from 'react';

import classes from './feature.module.scss';

import { MdDoneAll } from "react-icons/md";

class Feature extends React.Component {
    render() {
        return (
            <div>
                <div className={classes.feature}>
                    <div className={classes.flex_wrapper}>
                        <MdDoneAll className={classes.icon}/>
                        <p className={classes.text}>{this.props.text}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Feature;