import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './peoples.module.scss';


import sam from './img/1.jpg';
import jeff from './img/2.jpg';
import sarahf from './img/3.jpg';
import ferdous from './img/5.jpg';
import snell from './img/6.jpg';
import karla from './img/7.jpg';
import jason from './img/8.jpg';
import eric from './img/9.jpg';
import cox from './img/10.jpg';
import ted from './img/11.jpg';
import phelps from './img/12.jpg';
import coop from './img/14.jpg';
import peter from './img/15.jpg';
import eddie from './img/16.jpg';
import Ian from './img/17.jpg';
import klee from './img/18.jpg';
import norma from './img/19.jpg';
import charles from './img/20.jpg';
import bever from './img/21.jpg';
import vance from './img/22.jpg';
import james from './img/23.jpg';
import gour from './img/25.jpg';
import linda from './img/26.jpg';



import noimg from './img/noimg.png';


import PageTitle from '../../page_title/page_title.component';
import Card from '../../card/card.component';

class Peoples extends React.Component {
    render() {
        return (
            <div className={classes.peoples}>
                <Helmet>
                    <title>Our Peoples | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <PageTitle title='Our Peoples' />
                <div className={classes.container}>
                    <div className={classes.grid_wrapper}>
                        <Card 
                            name='Sam Ross'
                            designation='CEO'
                            image={sam}
                        />
                        <Card 
                            name='Jeff Grayson'
                            designation='CFO'
                            image={jeff}
                        />
                        <Card 
                            name='Sarah Farley'
                            designation='HR/Admin'
                            image={sarahf}
                        />
                        <Card 
                            name='Kingkar Verma'
                            designation='Marketing Strategist'
                            image={noimg}
                        />
                        <Card 
                            name='Ferdous Shareef'
                            designation='Project Manager'
                            image={ferdous}
                        />
                        <Card 
                            name='William M. Snell'
                            designation='Lead Web Developer'
                            image={snell}
                        />
                        <Card 
                            name='Karla J. Harris'
                            designation='Web Developer'
                            image={karla}
                        />
                        <Card 
                            name='Jason Bond'
                            designation='Web Developer'
                            image={jason}
                        />
                        <Card 
                            name='Eric Pippen'
                            designation='Lead Software Developer'
                            image={eric}
                        />
                        <Card 
                            name='Shirley Cox'
                            designation='Software Developer'
                            image={cox}
                        />
                        <Card 
                            name='Ted C. Hill'
                            designation='Software Developer'
                            image={ted}
                        />
                        <Card 
                            name='Lloyd O. Phelps'
                            designation='Junior Software Developer'
                            image={phelps}
                        />
                        <Card 
                            name='Ibrahim Kabir'
                            designation='Junior Software Developer'
                            image={noimg}
                        />
                        <Card 
                            name='Deborah Coop'
                            designation='Lead App Developer'
                            image={coop}
                        />
                        <Card 
                            name='Peter E. Tritt'
                            designation='App Developer'
                            image={peter}
                        />
                        <Card 
                            name='Eddie I. Messner'
                            designation='Junior App Developer'
                            image={eddie}
                        />
                        <Card 
                            name='Ian R. Rule'
                            designation='Junior App Developer'
                            image={Ian}
                        />
                        <Card 
                            name='Juliet Klee'
                            designation='Lead Designer'
                            image={klee}
                        />
                        <Card 
                            name='Norma Woodward'
                            designation='Designer'
                            image={norma}
                        />
                        <Card 
                            name='Charles M. Anderson'
                            designation='Lead Data Scientist'
                            image={charles}
                        />
                        <Card 
                            name='Jerrald J. Beverly'
                            designation='Data Analyst'
                            image={bever}
                        />
                        <Card 
                            name='Eloise J. Vance'
                            designation='Data Analyst'
                            image={vance}
                        />
                        <Card 
                            name='James Fuentes'
                            designation='Lead ML Scientist'
                            image={james}
                        />
                        <Card 
                            name='David V. McCullough'
                            designation='ML Scientist'
                            image={noimg}
                        />
                        <Card 
                            name='Zdenek Gour'
                            designation='ML Scientist'
                            image={gour}
                        />
                        <Card 
                            name='Linda Fritts'
                            designation='Junior ML Scientist'
                            image={linda}
                        />
                    </div>
                </div>
                    
            </div>
        )
    }
}

export default Peoples;