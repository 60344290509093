import React from 'react';

import classes from './features.module.scss';

import Feature from '../feature/feature.component';

class Features extends React.Component {
    render() {

        let features = this.props.features

        let renderFeatureList = features.map((feature) => <Feature text={feature} key={feature}/>)

        return (
            <div className={classes.features}>
                {renderFeatureList}
            </div>
        )
    }
}

export default Features;