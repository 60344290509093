import React from 'react';
import { Helmet } from 'react-helmet';

import classes from './login_portal.module.scss';

import PageTitle from '../../page_title/page_title.component';

class LoginPortal extends React.Component {

    state = {
        error: null,
        eid: "",
        password: ""
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.eid.length === 0 || this.state.eid.length !== 8){
            this.setState({error: "Please enter a 8 digit Employee ID!"})
        } else if (this.state.password.length === 0) {
            this.setState({error: "Please enter a valid password!"})
        } else {
            this.setState({error: "Server down, Please try again later!"})
        }
        
        
    }

    render() {
        return (
            <div className={classes.login}>
                <Helmet>
                    <title>Login | Se7enCoders</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <PageTitle title='Login Portal' />
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        <form className={classes.form} onSubmit={this.handleSubmit}>
                            <div className={classes.title}>
                                <h2>Please Login</h2>
                                <p className={classes.subtitle}>Please enter your login information here</p>
                            </div>
                            <div className={classes.input_wrapper}>
                                <div className={classes.input_group}>
                                    <label>Employee ID</label>
                                    <input 
                                        type='number' 
                                        value={this.state.eid} 
                                        onChange={e => this.setState({eid: e.target.value})}
                                    />
                                </div>
                                <div className={classes.input_group}>
                                    <label>Password</label>
                                    <input 
                                        type='password' 
                                        value={this.state.password} 
                                        onChange={e => this.setState({password: e.target.value})}
                                    />
                                        
                                </div>
                                <p className={classes.error}>{this.state.error}</p>
                                <div className={classes.submit_group}>
                                    
                                    <input className={classes.submit} type='submit' value='Login'/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginPortal;