import React from 'react';
import {Helmet} from "react-helmet";
import classes from './homepage.module.scss';


import { ReactSVG } from 'react-svg';


import { FaGlobe } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa";
import { FaBrain } from "react-icons/fa";


import Card from '../../card/card.component';
import MyTypist from '../../typist/typist.component';
import Button from '../../button/button.component';
import CircleCountUp from '../../circle_countup/circle_countup.component';

class HomePage extends React.Component {

    render() {
        return (
            <div className={classes.home}>
                <Helmet>
                    <title>Se7enCoders | Web, App, Software, Data Science and Machine Learning Solutions</title>
                    <meta name="description" content="Experience best ever Web, App, Software, Data Science and Machine Learning Solutions. Se7enCoders offers best custom coding and analytics services at very transparent price." />
                </Helmet>
                <div className={classes.container}>
                    <div className={classes.flex_wrapper}>
                        <div className={classes.content}>
                            <div className={classes.typist}>
                                <MyTypist />
                            </div>
                            
                            <div className={classes.btn}>
                                <Button text="Explore us" link='/services'/>
                            </div>
                        </div>
                        <div className={classes.img}>
                            <ReactSVG src="home.svg" alt="Se7enCoders"/>
                        </div>
                    </div>
                    <div className={classes.services_grid}>
                        <Card 
                            icon={<FaGlobe />} 
                            title='Web Development'
                            subtitle='Custom Websites with the latest technology available'
                            link='services'
                        />
                        <Card 
                            icon={<FaMobileAlt />} 
                            title='App Development'
                            subtitle='Mobile app development service as your requirements'
                            link='services'
                        />
                        <Card 
                            icon={<FaLaptopCode />} 
                            title='Software Development'
                            subtitle='Efficient and realiable software solutions for your company'
                            link='services'
                        />
                        <Card 
                            icon={<FaLayerGroup />} 
                            title='UI/UX Design'
                            subtitle='Creatively Crafted Pixel perfect UI design for you website/app.'
                            link='services'
                        />
                        <Card 
                            icon={<FaDatabase />} 
                            title='Data Analysis'
                            subtitle='Get valuable insights and information reports from your data'
                            link='services'
                        />
                        <Card 
                            icon={<FaBrain />} 
                            title='Machine Learning'
                            subtitle='Predict the unknown and stay one step ahead of your competitiors'
                            link='services'
                        />
                    </div>
                    <div className={classes.counter}>
                        <CircleCountUp end={487} title='Projects Completed'/>
                        <CircleCountUp end={151} title='Valuable Clients'/>
                        <CircleCountUp end={30} title='Skilled Peoples'/>
                        
                    </div>
                </div>
            </div>
            
        )
    }
}

export default HomePage;