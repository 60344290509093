import React from 'react'

import classes from './header.module.scss';
import HeaderPrimary from './header_primary/header_primary.component';
import HeaderSecondary from './header-secondary/header_secondary.component';

class Header extends React.Component {

    state = {primaryActive: true}

    handleSecondaryClick = () => {
        this.setState((prevState) => {
            return {primaryActive: false}
        });
    }

    handlePrimaryClick = () => {
        this.setState((prevState) => {
            return {primaryActive: true}
        });
    }

    render() {
        return (
            <div>
                <div className={classes.homepage}>
                    <HeaderSecondary click={this.handleSecondaryClick} data={this.state.primaryActive}/>
                    <HeaderPrimary click={this.handlePrimaryClick} data={this.state.primaryActive}/>
                </div>
                <div className={classes.hidden}>
                    
                </div>
            </div>
        )
    }
}

export default Header;