import React from 'react';

import classes from './circle_countup.module.scss';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


class CircleCountUp extends React.Component {
    render() {
        return (
            <div className={classes.counter}>
                <div className={classes.out_circle}>
                    <div className={classes.circle}>
                        <CountUp end={this.props.end} redraw={true} >
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} className={classes.countup}/>
                                </VisibilitySensor>
                            )}
                        </CountUp>
                    </div>
                </div>
                <div className={classes.title}>
                    <h2>{this.props.title}</h2>
                </div>
            </div>

        )
    }
}

export default CircleCountUp;