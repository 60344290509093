import React, {useState, useEffect} from 'react'

import classes from './typist.module.scss';
import { ReactTyped } from 'react-typed';


const MyTypist = () => {

    const [count, setCount] = useState(1);

    useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("Count: " + count);
    setCount(1);
    }, [count]);


    return (
        <div className={classes.typist}>
            <h1>We Are </h1>
            {count ? (
                <ReactTyped
                    style={{ fontWeight: '300', fontSize: '4rem'}} 
                    typeSpeed={40}
                    backSpeed={50} strings={[
                    'Se7enCoders',
                    'Web Developers',
                    'App Developers',
                    'UI/UX Experts',
                    'ML Engineers',
                    'Your Friend!'
                ]} loop />
            ) : (
                ""
            )}
            
        </div>
    )
}

export default MyTypist;